/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
    margin: 0px;
}


.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
